.ws-picker {
  background-color: #ffffff;
  border: 1px solid black;
  border-radius: 0.3rem;
  overflow-y: auto;
  max-height: 40vh;

  &__item {
    cursor: pointer;
    padding: 0.5rem;
    font-style: italic;
    &--active {
      @extend .ws-picker__item;
      background-color: #d3d3d3;
    }
  }
}
