@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/mixins/_breakpoints.scss';

.mm-stepper {
  overflow-x: auto;
  margin-bottom: 10rem;
  margin-top: 4rem;

  @include media-breakpoint-down(sm) {
    margin-bottom: 2rem;
    margin-top: 2rem;
  }

  &__number {
    $mm-size: 1.7rem;
    height: $mm-size;
    width: $mm-size;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 2px solid var(--bs-body-color);
  }

  &__spacer {
    background-color: #d3d3d3;
    align-self: center;
    justify-self: center;
    flex: 1;
    min-width: 4rem;
    position: relative;
    display: inline-block;
    margin: 0;
    padding: 0;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 30%;
      height: 0.06rem;
      background-color: #d3d3d3;
    }
  }

  &__step {
    &--active {
      .mm-stepper__number {
        background-color: var(--bs-body-color);
        color: #f9f9f9;
      }
    }
    &--done {
      .mm-stepper__number {
        background-color: var(--bs-primary);
        border-color: var(--bs-primary);
        color: var(--bs-primary);
        position: relative;

        $checkmark-thickness: 0.1rem;
        $checkmark-color: #f9f9f9;
        $bottom-offset: calc(#{$checkmark-thickness} + 0.4rem);

        &::after {
          content: '';
          height: 50%;
          width: $checkmark-thickness;
          background-color: $checkmark-color;
          position: absolute;
          bottom: $bottom-offset;
          left: 40%;
          transform-origin: 0 100%;
          rotate: 40deg;
        }

        &::before {
          content: '';
          height: $checkmark-thickness;
          width: 20%;
          background-color: $checkmark-color;
          position: absolute;
          bottom: 0.4rem;
          left: calc(#{$checkmark-thickness} + 20%);
          transform-origin: 100% 0;
          rotate: 40deg;
        }
      }
    }
  }
}
