.home-container {
  &__powered-by {
    background-color: #ffffff;
    border: 1px solid #d3d3d3;
    border-radius: 1rem;

    &__logo {
      border-bottom: 1px solid #d3d3d3;
    }
  }

  &__list {
    li {
      margin-bottom: 1.5rem;
    }
  }
}
