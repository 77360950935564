/* NG: These are custom overrides for boostrap throughout the app */

$body-color: #707070;
@import '~bootstrap/scss/bootstrap.scss';

/* Bootstrap overrides end*/

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

.App {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d3d3d3;
}

.app_container {
  background: #f9f9f9;
  width: 100%;
  height: 100%;
}
