.ws-init {
  background-color: #ffffff;
  border: 1px solid #d3d3d3;
  border-radius: 0.3rem;
  overflow-y: auto;
  max-height: 40vh;

  &__item {
    // padding: 0.5rem;
    &--loading {
      @extend .ws-init__item;
      // background-color: #d3d3d3;
      &::after {
        // content: '...';
      }
    }
  }
}
