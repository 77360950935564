.navbar-nav .nav-link {
  font-family: Helvetica Neue;
  color: #707070 !important;
}

.logo-container {
  font-family: Helvetica Neue;
  color: #707070;

  &__subheader {
    font-size: 1.1rem;
  }
}
